import { ChevronLeftIcon } from 'assets/icons_v3/fonts'
import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import RouletteTermsModal from 'components/Modals/RouletteTerms'
import RouletteHowWorksComponent from 'components/Roulette/HowWorks'

export default function RouletteHowWorks() {
    const navigate = useNavigate()
    const [policyTermsVisible, setPolicyTermsVisible] = useState(false)

    const onBackward = () => {
        navigate('/roulette')
    }

    const onTogglePolicyTerms = () => {
        setPolicyTermsVisible((visible) => !visible)
    }

    return (
        <Fragment>
            <ChevronLeftIcon
                className="cursor-pointer w-2 mb-12"
                onClick={onBackward}
            />

            <div className="mb-10">
                <h1 className="text-dark-kiwi font-semibold text-2xl">
                    ¿Cómo participar?
                </h1>
            </div>

            <RouletteHowWorksComponent />

            <div className="text-xs text-gray-500-kiwi mt-auto">
                Al participar en nuestra ruleta, confirmo que he leído y estoy
                de acuerdo con el{' '}
                <span
                    className="text-blue-kiwi underline cursor-pointer"
                    onClick={onTogglePolicyTerms}>
                    Términos y condiciones
                </span>{' '}
                de la Ruleta de premios Kiwi.
            </div>

            <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={onBackward}>
                Participar
            </button>

            <RouletteTermsModal
                isVisible={policyTermsVisible}
                closeModal={onTogglePolicyTerms}
            />
        </Fragment>
    )
}
