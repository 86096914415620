import './style.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Wheel } from 'react-custom-roulette'
import RouletteMarkerSVG from 'assets/roulette_marker.svg'
import { RouletteContext } from 'context/RouletteContext'
import dayjs from 'dayjs'
import RouletteActiveModal from 'components/Modals/RouletteActive'
import ConfettiExplosion from 'react-confetti-explosion'

const duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

export default function RouletteComponent({ className, data }) {
    const { state: rouletteState } = useContext(RouletteContext)
    const [rouletteModalVisible, setRouletteModalVisible] = useState(false)
    const [showConfetti, setShowConfetti] = useState(false)

    /** Si hay eventos disponibles */
    const [availableEvents, setAvailableEvents] = useState(false)

    /** Si ya pasó la fecha de inicio del evento */
    const [startDatePassed, setStartDatePassed] = useState(false)

    /** Si ya pasó la fecha final del evento */
    const [endDatePassed, setEndDatePassed] = useState(false)

    const [, setRenderTick] = useState(false)

    useEffect(() => {
        setAvailableEvents(rouletteState.events.length > 0)
        setStartDatePassed(() =>
            dayjs().isAfter(dayjs(rouletteState.lastEvent.start))
        )
        setEndDatePassed(() =>
            dayjs().isAfter(dayjs(rouletteState.lastEvent.end))
        )

        const timer = setInterval(() => {
            setRenderTick((state) => !state)
        }, 1000)

        return () => clearInterval(timer)
    }, [rouletteState])

    function calculateTimeLeft() {
        const now = dayjs()
        const target = dayjs(rouletteState.lastEvent.start)
        const diff = target.diff(now)

        if (diff <= 0) {
            return {
                days: '00',
                hours: '00',
                minutes: '00',
                seconds: '00'
            }
        }

        const duration = dayjs.duration(diff)

        /** Función para agregar un cero delante si es necesario */
        const formatNumber = (number) => {
            return number < 10 ? `0${number}` : `${number}`
        }

        return {
            days: formatNumber(duration.days()),
            hours: formatNumber(duration.hours()),
            minutes: formatNumber(duration.minutes()),
            seconds: formatNumber(duration.seconds())
        }
    }

    const rouletteTextButton = () => {
        /** Mostrar si no hay eventos  */
        if (!availableEvents) {
            return 'Próximamente'
        }

        /** Mostrar "contador" antes de la fecha de inicio */
        if (startDatePassed === false) {
            const { days, hours, minutes, seconds } = calculateTimeLeft()

            if (
                days === '00' &&
                hours === '00' &&
                minutes === '00' &&
                seconds === '00'
            ) {
                setStartDatePassed(true)
            }

            return (
                <Fragment>
                    <div className="text-sm font-medium">Juega en</div>{' '}
                    {days > 0 ? `${days} días` : ''} {hours}:{minutes}:{seconds}
                </Fragment>
            )
        }

        /** Mostrar "Juega ahora" si estamos en el rango de fecha de inicio y final */
        if (startDatePassed === true && endDatePassed === false) {
            return 'Jugar ahora'
        }

        /** Mostrar "Próximamente" si la fecha final ya pasó */
        if (endDatePassed === true) {
            return 'Próximamente'
        }
    }

    const onRouletteAction = () => {
        if (startDatePassed === true && endDatePassed === false) {
            setRouletteModalVisible(true)
            setShowConfetti(true)
        }
    }

    return (
        <div
            className={`mx-auto overflow-hidden relative flex flex-col ${className}`}>
            <img
                src={RouletteMarkerSVG}
                className="absolute left-1/2 -translate-x-1/2 z-10"
            />

            {showConfetti && (
                <div className="mx-auto">
                    <ConfettiExplosion
                        colors={['#ffcc7f ', '#ff9900 ', '#ffc166', '#ffd699 ']}
                        particleCount={30}
                        particleSize={20}
                    />
                </div>
            )}

            <div className="mx-auto">
                <Wheel
                    radiusLineWidth={0}
                    outerBorderColor="#022E56"
                    outerBorderWidth={16}
                    mustStartSpinning={false}
                    prizeNumber={0}
                    data={data}
                    spinDuration={0.5}
                    startingOptionIndex={12}
                    disableInitialAnimation={true}
                    textDistance={70}
                    fontFamily="Poppins"
                    fontSize={18}
                    fontWeight={600}
                />
            </div>

            <div
                onClick={onRouletteAction}
                className="text-center w-44 absolute z-10 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 rounded-lg bg-orange-kiwi px-2 py-2 text-white font-semibold cursor-pointer">
                {rouletteTextButton()}
            </div>

            <RouletteActiveModal
                isVisible={rouletteModalVisible}
                closeModal={() => setRouletteModalVisible(false)}
            />
        </div>
    )
}
