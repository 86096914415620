import {
    ChevronLeftIcon,
    CurrencyDollar2Icon,
    CurrencyDollarIcon,
    Wallet3Icon,
    QuestionIcon,
    TicketIcon,
    Wineglass2Icon,
    AirbnbIcon
} from 'assets/icons_v3/fonts'
import RouletteHowWorks from 'components/Modals/RouletteHowWorks'
import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function RoulettePrizes() {
    const navigate = useNavigate()
    const [howWorksVisible, setHowWorksVisible] = useState(false)

    const onBackward = () => {
        navigate('/roulette')
    }

    const onToggleHowWorks = () => {
        setHowWorksVisible((visible) => !visible)
    }

    return (
        <Fragment>
            <div className="mb-12 flex justify-between">
                <ChevronLeftIcon
                    className="cursor-pointer w-2"
                    onClick={onBackward}
                />

                <div onClick={onToggleHowWorks} className="cursor-pointer ">
                    <QuestionIcon />
                </div>
            </div>

            <h1 className="text-dark-kiwi font-semibold text-2xl mb-8">
                Premios de la semana
            </h1>

            <div className="grid grid-cols-2 gap-4 text-regal-blue-950-kiwi">
                <div className="flex items-center gap-4 col-span-2 border border-slate-200-kiwi rounded-xl p-4">
                    <div className="w-10 h-10 rounded-full bg-golden-100-kiwi text-golden-500-kiwi flex">
                        <CurrencyDollar2Icon className="m-auto" />
                    </div>
                    <div>
                        <div className="font-semibold">Efectivo de $3,000</div>
                        <div className="text-gray-600-kiwi text-xs">
                            Premio de mayor valor
                        </div>
                    </div>
                </div>
                <div className="border border-slate-200-kiwi rounded-xl p-4 text-center">
                    <div className="w-10 h-10 rounded-full bg-slate-100-kiwi mx-auto mb-4 flex">
                        <CurrencyDollarIcon className="m-auto" />
                    </div>
                    <div>
                        <div className="font-semibold text-sm">
                            Efectivo desde $500 a $50
                        </div>
                    </div>
                </div>
                <div className="border border-slate-200-kiwi rounded-xl p-4 text-center">
                    <div className="w-10 h-10 rounded-full bg-slate-100-kiwi mx-auto mb-4 flex">
                        <Wallet3Icon className="m-auto" />
                    </div>
                    <div>
                        <div className="font-semibold text-sm">
                            Abonos desde $500 a $50
                        </div>
                    </div>
                </div>
                <div className="border border-slate-200-kiwi rounded-xl p-4 text-center">
                    <div className="w-10 h-10 rounded-full bg-slate-100-kiwi mx-auto mb-4 flex">
                        <TicketIcon className="m-auto" />
                    </div>
                    <div>
                        <div className="font-semibold text-sm">
                            Entradas de cine VIP
                        </div>
                    </div>
                </div>
                <div className="border border-slate-200-kiwi rounded-xl p-4 text-center">
                    <div className="w-10 h-10 rounded-full bg-slate-100-kiwi mx-auto mb-4 flex">
                        <Wineglass2Icon className="m-auto" />
                    </div>
                    <div>
                        <div className="font-semibold text-sm">
                            Cena para 2 personas
                        </div>
                    </div>
                </div>
                <div className="border border-slate-200-kiwi rounded-xl p-4 text-center">
                    <div className="w-10 h-10 rounded-full bg-slate-100-kiwi mx-auto mb-4 flex">
                        <AirbnbIcon className="m-auto" />
                    </div>
                    <div>
                        <div className="font-semibold text-sm">
                            Noche en Airbnb
                        </div>
                    </div>
                </div>
            </div>

            <RouletteHowWorks
                isVisible={howWorksVisible}
                closeModal={onToggleHowWorks}
            />
        </Fragment>
    )
}
