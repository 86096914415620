import React, { Fragment, useContext, useState } from 'react'
import { AuthContext } from 'context/AuthContext'
import { useNavigate } from 'react-router-dom'

import SuccessOnboardingSVG from 'assets/onboarding_v3/phone_success.svg'
import Button from 'components/Button'

export default function ChangePhoneSuccess() {
    const { setState: setAuthState } = useContext(AuthContext)
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const onContinue = async () => {
        if (loading === true) {
            return
        }

        try {
            setLoading(true)

            setAuthState((prev) => ({
                ...prev,
                cellphone: prev.new_cellphone,
                logged: true
            }))
            navigate('/home')
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Fragment>
            <div className="flex flex-col justify-center text-center m-auto mb-10">
                <img className="mx-auto mb-8" src={SuccessOnboardingSVG} />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    ¡Felicitaciones!
                </h1>
                <p>
                    ¡Felicidades! El cambio de su número de celular se ha
                    realizado con éxito
                </p>
            </div>

            <Button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
                loading={loading}
                onClick={onContinue}>
                Explorar
            </Button>
        </Fragment>
    )
}
