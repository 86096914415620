import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { UserStarIcon } from 'assets/icons_v3/fonts'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RouletteContext } from 'context/RouletteContext'
import formatDate from 'utils/formatDate'
import BackwardButton from 'components/Button/Backward'

export default function RouletteWinners() {
    const navigate = useNavigate()
    const { state: rouletteState } = useContext(RouletteContext)
    const [selectedEvent, setSelectedEvent] = useState({})
    const [events, setEvents] = useState([])

    useEffect(() => {
        let lastEvents = rouletteState.events
            .filter((e) => e.winners.length > 0)
            .sort((a, b) => new Date(b.start) - new Date(a.start))

        const lastEvent = lastEvents.length
            ? lastEvents[0]
            : rouletteState.lastEvent

        setSelectedEvent(lastEvent)
        setEvents(lastEvents)
    }, [])

    const onBackward = () => {
        navigate('/roulette')
    }

    return (
        <Fragment>
            <BackwardButton onBackward={onBackward} />

            <Swiper
                className="w-full cursor-grab text-center"
                spaceBetween={16}
                centeredSlides={false}
                slidesPerView={3.3}>
                {events.map((event, index) => (
                    <SwiperSlide
                        key={index}
                        onClick={() => setSelectedEvent(event)}
                        className={`w-full capitalize px-2 py-2 border border-regal-blue-950-kiwi rounded-xl text-xs font-semibold ${
                            event.start === selectedEvent.start
                                ? 'bg-regal-blue-950-kiwi/20'
                                : ''
                        }`}>
                        {formatDate(event.start, 'MMM, DD')}
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className="flex flex-col gap-4 mt-8">
                {selectedEvent.winners?.map((winner, index) => (
                    <div
                        className="flex items-center gap-4 rounded-xl p-4"
                        key={winner.name + index}>
                        <div className="w-10 h-10 rounded-full bg-slate-100-kiwi text-regal-blue-950-kiwi flex">
                            <UserStarIcon className="m-auto" />
                        </div>
                        <div>
                            <div className="font-semibold text-regal-blue-950-kiwi">
                                {winner.name}
                            </div>
                            <div className="text-gray-600-kiwi text-xs">
                                {winner.location}
                            </div>
                        </div>
                        <div className="ml-auto font-semibold">
                            {winner.prize}
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    )
}
